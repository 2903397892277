import React, { Fragment, useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Box, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import { currencyList } from "@utils";
import { countryList } from "@utils/countries";
import CustomizedBreadcrumbs from "@components/CustomBreadcrumbs";
import { useGetBalancePerCountry } from "@modules/balance/hooks/useGetBalancePerCountry";
import { useAppBalance } from "../../../modules/Inter-transfer/hooks/useAppBalance";
import BalanceSkeleton from "../../../modules/balance/components/BalanceSkeleton";
import BalanceItem from "../../../modules/balance/components/BalanceItem";
import CustomButton from "../../../components/CustomButton";

const Finances = () => {
  const { appID } = useParams();
  const [finances, setFinances] = useState([]);
  const { data, isLoading } = useGetBalancePerCountry();
  const {
    data: AppBalance,
    isLoading: BalanceLoading,
    refetch,
  } = useAppBalance();

  const balance = data?.data;

  //==================== TRANSFORM DATA FXN =============================

  const tranformData = (data) => {
    const countriesList = [];
    const result = [];

    for (const [country, providers] of Object.entries(data)) {
      const getLabel = (country) =>
        countryList
          .filter((c) => c.value === country)
          .map((country) => country.label);

      if (country === "WORLD")
        countriesList.push({ label: "World", value: country });
      else countriesList.push({ label: getLabel(country), value: country });

      for (const [provider, transactions] of Object.entries(providers)) {
        const orderedTransactionsByCurrency = currencyList?.map((currency) =>
          transactions.filter(
            (transaction) => transaction?.currency === currency
          )
        );

        for (const orderedTransactionByCurrency of orderedTransactionsByCurrency) {
          let debit = 0,
            credit = 0;

          for (const transaction of orderedTransactionByCurrency) {
            if (transaction.action_type === "DEBIT")
              debit += parseFloat(transaction.total_amount);
            else if (transaction.action_type === "CREDIT")
              credit += parseFloat(transaction.total_amount);
          }

          if (debit !== 0 || credit !== 0)
            result.push({
              country,
              debit: debit.toFixed(1),
              credit: credit.toFixed(1),
              provider_id: provider || "N/A",
              currency:
                currencyList[
                  orderedTransactionsByCurrency.indexOf(
                    orderedTransactionByCurrency
                  )
                ],
              balance: debit + credit,
            });
        }
      }
    }
    return { result, countriesList };
  };

  useEffect(() => {
    if (balance && !isLoading) {
      const { result } = tranformData(balance);
      setFinances(result);
    }
  }, [appID, balance]);

  const breadCrumb = [
    {
      label: "Finances",
      link: `/${appID}/finances`,
      icon: <CurrencyExchangeOutlinedIcon fontSize="small" />,
    },
  ];

  //===================== COMPONENT =================

  return (
    <Fragment>
      <Stack
        direction="row"
        mb={4}
        mt={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomizedBreadcrumbs links={breadCrumb} />
      </Stack>
      <Box style={{ marginTop: "4rem" }}>
        <Grid container spacing={5}>
          {BalanceLoading ? (
            <BalanceSkeleton />
          ) : finances?.length ? (
            AppBalance?.data?.map((balance) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                key={JSON.stringify(balance)}
                lg={2}
              >
                <BalanceItem balance={balance} key={balance?.id} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12} textAlign="center">
              <Typography variant="h5">No data found</Typography>
              <CustomButton
                onClick={refetch}
                loading={BalanceLoading}
                startIcon={<RefreshIcon fontSize="large" />}
                size="large"
                variant="contained"
                sx={{ mt: 2, fontSize: 13 }}
              >
                Refresh
              </CustomButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default Finances;
