import { useMutation, useQueryClient } from "react-query";
import { updateProductAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig";

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: "update_product",
    mutationFn: updateProductAPI,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["products"],
      }),
    onError: (error) => errorHandler(error),
  });

  return mutation;
};
