import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import CustomizedBreadcrumbs from "@components/CustomBreadcrumbs";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import DataGrid from "@components/datagrid";
import TableLoader from "@components/TableLoader";
import { exportedDocsColumns } from "@modules/exportedDocs/utils/columns";
import { useExportedCashout } from "@modules/cashouts/hooks/useExportedCashout";
import { useExportedTransaction } from "@modules/transactions/hooks/useExportedTransaction";

export default function ExportedDocs() {
  const { appID } = useParams();
  const { data: cashouts, isLoading: isLoadingCashouts } = useExportedCashout();
  const { data: transactions, isLoading: isLoadingTransactions } =
    useExportedTransaction();
  const smallScreen = useMediaQuery("(max-width:900px)");

  const breadCrumb = [
    {
      label: "Exported docs",
      link: `/${appID}/exportedDocs`,
      icon: <DescriptionOutlinedIcon fontSize="small" />,
    },
  ];
  // ================= COMPONENT =================
  return (
    <Fragment>
      <Stack
        direction="row"
        mb={4}
        mt={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomizedBreadcrumbs links={breadCrumb} />
      </Stack>
      <Paper sx={{ px: smallScreen ? 1 : 5, py: 5 }}>
        {isLoadingTransactions || transactions?.data?.items?.length > 0 ? (
          isLoadingTransactions ? (
            <TableLoader rows={2} columns={exportedDocsColumns} />
          ) : (
            <>
              <Typography variant="h5" color="grey">
                Exported Transactions
              </Typography>
              <Divider sx={{ my: 1 }} />
              <DataGrid
                data={transactions?.data?.items}
                hidePadding
                showPagination={false}
                showSearch={false}
                column={exportedDocsColumns}
              />
            </>
          )
        ) : (
          <Box width="100%" my={2} textAlign="center">
            <PlagiarismIcon fontSize="large" />
            <Typography variant="h5" color="grey">
              No transaction exported
            </Typography>
          </Box>
        )}

        {isLoadingCashouts || cashouts?.data?.items?.length > 0 ? (
          isLoadingCashouts ? (
            <TableLoader rows={2} columns={exportedDocsColumns} />
          ) : (
            <>
              <Typography mt={5} variant="h5" color="grey">
                Exported Cashouts
              </Typography>
              <Divider sx={{ my: 1 }} />
              <DataGrid
                data={cashouts?.data?.items}
                hidePadding
                showPagination={false}
                showSearch={false}
                column={exportedDocsColumns}
              />
            </>
          )
        ) : (
          <Box width="100%" mt={10} textAlign="center">
            <PlagiarismIcon fontSize="large" />
            <Typography variant="h5" color="grey">
              No cashout exported
            </Typography>
          </Box>
        )}
      </Paper>
    </Fragment>
  );
}
