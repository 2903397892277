import React, { useState, Fragment } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import * as Flags from "country-flag-icons/react/3x2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";

import theme from "@src/theme";
import AssociatedPspItem from "./associatedPspItem";
import HelpAlert from "@components/HelpAlert";
import { countryList } from "@utils/countries";
import AssociatedPspLoader from "./associatedPspLoader";
import AlertDialog from "@components/AlertDialog";
import { useSubscribePsp } from "../hooks/useSubscribePsp";
import { errorHandler } from "@configs/errorConfig";
import { useUnsubscribePsp } from "../hooks/useUnsubscribePsp";
import { isApolloError } from "@apollo/client";

export default function AssociatedPsp({
  associatedData,
  singleApp,
  refetchAssociatedPsp,
  refetchApplicationPsp,
  isLoading,
}) {
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);
  const [selectedPsp, setSelectedPsp] = useState(false);
  const [unsubscribePsp, { error: errorUnsubscribe, loading: unsubscribing }] =
    useUnsubscribePsp();
  const [subscribePsp, { error: errorSubscribe, loading: subscribing }] =
    useSubscribePsp();
  const selectedApp = useSelector((store) => store.data.selectedApp);

  const onToggleSubscribe = (selectedPsp) => {
    setSelectedPsp(selectedPsp);
    setOpenSubscribeDialog(true);
  };

  const onSubscribePsp = () => {
    const isSubsribed = singleApp?.psp.some(
      (psp) => Number(psp?.id) === Number(selectedPsp?.id)
    );

    const toggleSubscribeEndpoint = isSubsribed
      ? unsubscribePsp({
          variables: { id: selectedApp?.id, pspId: selectedPsp?.id },
        })
      : subscribePsp({
          variables: { id: selectedApp?.id, pspId: selectedPsp?.id },
        });

    const error = isSubsribed ? errorUnsubscribe : errorSubscribe;

    toggleSubscribeEndpoint
      .then(() => {
        refetchAssociatedPsp();
        refetchApplicationPsp();
        setOpenSubscribeDialog(false);
        toast.success(
          `Provider ${selectedPsp?.name} ${
            isSubsribed ? "unsubscribed" : "subscribed"
          } successfully`
        );
      })
      .catch((err) => {
        if (isApolloError(err)) {
          console.log({ err });
          errorHandler(err);
        } else {
          errorHandler(error);
        }
      });
  };

  return (
    <Fragment>
      <Typography variant="h5">Associated PSPs</Typography>
      {isLoading ? (
        <AssociatedPspLoader />
      ) : !associatedData?.providers?.length > 0 ? (
        <Box sx={{ width: "70%", mx: "auto" }} my={2} textAlign="center">
          <ErrorOutlineOutlinedIcon color="warning" sx={{ fontSize: 50 }} />
          <Typography variant="h5" mt={2} color="grey">
            No payment providers have been associated with this application,
            please make sure you've submitted the kyc and it has been validated
          </Typography>
        </Box>
      ) : (
        <>
          <HelpAlert sx={{ mt: 2 }}>
            Click on a country to expand and view associated PSP.
          </HelpAlert>
          {associatedData?.countries?.map((country) => {
            const label = country;
            const Flag = Flags[country];

            return (
              <Box key={country} mt={1}>
                <Accordion sx={{ border: "initial", boxShadow: "initial" }}>
                  <AccordionSummary
                    sx={{ p: 0, m: 0 }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box
                      width="100%"
                      borderLeft={4}
                      borderColor={theme.palette.primary.main}
                      bgcolor="rgba(200,200,200, .3)"
                      p={2}
                    >
                      {label === "WORLD" ? (
                        <Stack direction="row" gap={0.4}>
                          <LanguageOutlinedIcon fontSize="small" />
                          <Typography>World</Typography>
                        </Stack>
                      ) : (
                        <Fragment>
                          <Flag width={20} title={label} />{" "}
                          {
                            countryList?.find(
                              (countryItem) => countryItem?.value === country
                            )?.label
                          }
                        </Fragment>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <Stack
                      flexDirection="row"
                      flexWrap="wrap"
                      gap={2}
                      width="100%"
                    >
                      {associatedData?.providers?.map((provider) =>
                        provider?.country_code === country &&
                        provider?.name &&
                        provider?.logo ? (
                          <AssociatedPspItem
                            key={provider?.id}
                            provider={provider}
                            singleApp={singleApp}
                            onToggleSubscribe={() =>
                              onToggleSubscribe(provider)
                            }
                          />
                        ) : null
                      )}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })}
        </>
      )}

      <AlertDialog
        open={openSubscribeDialog}
        onClose={() => setOpenSubscribeDialog(false)}
        action={onSubscribePsp}
        color="primary"
        loading={subscribing || unsubscribing}
        text={`Are you sure you want to ${
          singleApp?.psp.some(
            (psp) => Number(psp?.id) === Number(selectedPsp?.id)
          )
            ? "unsubscribe"
            : "subscribe"
        } to ${selectedPsp?.name} ?`}
      />
    </Fragment>
  );
}
